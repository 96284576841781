import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../components/Container"
import Heading from "../components/Heading"
import P from "../components/Paragraph"
import Box from "../components/Box"
import Grid from "../components/Grid"
import PageHeader from "../components/PageHeader"
import Wave from "../components/Wave"
import SeoHelmet from "../components/Seo"

const ServicesPage = () => {
  const servicesData = useStaticQuery(graphql`
    query ServicesQuery {
      allDatoCmsService(sort: { fields: position, order: ASC }) {
        nodes {
          id
          title
          intro
          icon {
            alt
            url
            title
          }
        }
      }

      icons: file(
        name: { eq: "global-development" }
        extension: { eq: "svg" }
      ) {
        id
        name
        publicURL
      }
    }
  `)

  const services = servicesData.allDatoCmsService.nodes

  return (
    <>
      <SeoHelmet
        title="Services"
        description="We love complex data and storytelling challenges — find out how we can help you."
        url={`http://lapidus.se/services`}
      />
      <PageHeader
        title="Services"
        subtitle="We love complex data and storytelling challenges — find out how we can help you."
      />
      <Wave color="lightGrey" mt="5rem" variant="top2" />
      <Box bg="lightGrey">
        <Container size="lg" py="3rem">
          <Grid>
            {services.map(({ id, title, intro, icon }) => (
              <Box
                key={id}
                gridColumn={["span 12", "span 6", null, "span 4"]}
                textAlign="center"
                mb={["3rem", "5rem"]}
              >
                <img src={icon.url} alt={icon.title} />
                <Heading as="h3" fontSize="lg" my="0.75rem">
                  {title}
                </Heading>
                <P size="md" color="darkGrey" my="0">
                  {intro}
                </P>
              </Box>
            ))}
          </Grid>
        </Container>
      </Box>
      <Wave color="lightGrey" variant="bottom3" bg="navy" />
    </>
  )
}

export default ServicesPage
